<template>
  <v-container fluid class="pt-0 fill-height pay-table-wrapper">
      <v-row class="fill-height">
        <v-col cols="12">
          <v-card style="height: 100%">
            <v-card-text>
              <div class="pay-table">
                <div class="pay-table-header">
                  <div class="pay-table-row">
                    <div class="pay-table-col">
                      №
                    </div>
                    <div class="pay-table-col editable">
                      Організація
                    </div>
                    <div class="pay-table-col">
                      ЄДРПОУ
                    </div>
                    <div class="pay-table-col">
                      Користувачів
                    </div>
                    <div class="pay-table-col">
                      Перс.кабінет
                    </div>
                    <div class="pay-table-col">
                      Не активовано
                    </div>
                    <div class="pay-table-col">
                      За сьогодні
                    </div>
                  </div>
                </div>
                <div class="pay-table-body" style="min-height: 40vh">
                  <div class="pay-table-row"
                       v-for="(item, idx) in items"
                       :key="idx"
                  >
                    <div class="pay-table-col" style="padding-left: 16px !important;">
                      {{ idx + 1 }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.organization }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.code }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.users }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.cabinet }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.inactive }}
                    </div>
                    <div class="pay-table-col">
                      {{ item.today }}
                    </div>
                  </div>
                </div>
                <div class="pay-table-total">
                  <div class="pay-table-row">
                    <div class="pay-table-col">
                     Всього:
                    </div>
                    <div class="pay-table-col">
                      {{ total('users') }}
                    </div>
                    <div class="pay-table-col">
                      {{ total('cabinet') }}
                    </div>
                    <div class="pay-table-col">
                      {{ total('inactive') }}
                    </div>
                    <div class="pay-table-col">
                      {{ total('today') }}
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import organizationAPI from "@/utils/axios/organization"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "UserStats",
  data() {
    return {
      items: []
    }
  },
  methods: {
    total(field) {
      return this.items.reduce((acc, item) => acc + item[field], 0)
    }
  },
  created() {
    organizationAPI.get_user_stats()
      .then(response => response.data)
      .then(data => {
        this.items = data
      })
      .catch(err => {
        const error = err.response.data.detail;
        this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
      })
  }
}
</script>

<style scoped lang="scss">
  .pay-table-col {
    padding: 4px 2px;

    &:nth-child(1) {
      flex: 0 1 60px;
      padding-left: 16px !important;
      min-width: 60px;
    }

    &:nth-child(2) {
      flex: 0 1 260px;
      min-width: 260px;
    }

    &:nth-child(3) {
      flex: 0 1 120px;
      min-width: 120px;
    }

    &:nth-child(4) {
      flex: 0 1 130px;
      min-width: 130px;
    }

    &:nth-child(5) {
      flex: 0 1 130px;
      min-width: 130px;
    }

    &:nth-child(6) {
      flex: 0 1 130px;
      min-width: 130px;
    }

    &:nth-child(7) {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      min-width: 180px;
    }
  }

  .pay-table-total {
    .pay-table-col {
      &:nth-child(1) {
        flex: 0 1 440px;
        padding-left: 16px !important;
        min-width: 440px;
      }

      &:nth-child(2) {
        flex: 0 1 130px;
        min-width: 130px;
      }

      &:nth-child(3) {
        flex: 0 1 130px;
        min-width: 130px;
      }

      &:nth-child(4) {
        flex: 0 1 130px;
        min-width: 130px;
      }

      &:nth-child(5) {
        flex: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        min-width: 180px;
      }
    }
  }
</style>